import React from 'react'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import breadcumb from './bread (1).jpg'
import abstract from './arrows11.png'

import img1 from './resize images/1.jpg'
import img2 from './resize images/2.jpg'
import img3 from './resize images/3.jpg'
import img4 from './resize images/4.jpg'
import img5 from './resize images/5.jpg'
import img6 from './resize images/6.jpg'
import img7 from './resize images/7.jpg'
export default function Gallery() {
  return (
    <>
    
<Header/>
    
<div class="breadcrumb">
    <img src={breadcumb} alt="Breadcrumb Image" class="breadcrumb-image"/>
    <span class="breadcrumb-text"> Gallery </span>
</div>


<section id='footerimg'>


    
<div className='container-fluid'>
    <div className="row">
        <div className='col-md-3'>

        <div class="card">
  <div class="image-container">
  <img src={img1} alt="Match image" class="card-image" />
  <div class="overlay11">
      <span>Chandigarh
 Trails</span>
    </div>
  </div>
</div>




        </div>



        <div className='col-md-3'>

<div class="card">
<div class="image-container">
<img src={img2} alt="Match image" class="card-image" />
<div class="overlay11">
<span>Chandigarh
 Trails</span>
</div>
</div>
</div>




</div>









<div className='col-md-6'>

<div class="card">
<div class="image-container">
<img src={img3} alt="Match image" class="card-image" />
<div class="overlay11">
<span>Chandigarh
 Trails</span>
</div>
</div>
</div>




</div>



<div className='col-md-6'>

<div class="card">
<div class="image-container">
<img src={img5} alt="Match image" class="card-image" />
<div class="overlay11">
<span>Chandigarh
 Trails</span>
</div>
</div>
</div>




</div>




<div className='col-md-3'>

<div class="card">
<div class="image-container">
<img src={img5} alt="Match image" class="card-image" />
<div class="overlay11">
<span>Chandigarh
 Trails</span>
</div>
</div>
</div>




</div>





<div className='col-md-3'>

<div class="card">
<div class="image-container">
<img src={img6} alt="Match image" class="card-image" />
<div class="overlay11">
<span>Chandigarh
 Trails</span>
</div>
</div>
</div>




</div>





    </div>
</div>
</section>
    <Footer/>
    </>
  )
}
