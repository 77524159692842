import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import abstract from "./arrows11.png";
import abstract1 from "./arrowsL.png";
import { InstagramEmbed } from "react-social-media-embed";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import breadcumb from "./bread (1).jpg";
import { FacebookEmbed } from "react-social-media-embed";

export default function Social() {
  const options = {
    loop: true,
    nav: false,
    dots: false,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1.8, // 1 item for small screens
        margin: 10,
        autoplayTimeout: 1000,
      },
      600: {
        items: 2, // 2 items for medium screens
      },
      1000: {
        items: 4.4, // 3 items for large screens
      },
    },
  };

  return (
    <>
      <Header />
      <div className="breadcrumb">
        <img
          src={breadcumb}
          alt="Breadcrumb Image"
          className="breadcrumb-image"
        />
        <span className="breadcrumb-text"> Social Media</span>
      </div>

      <section id="socialplatform">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 col-12">
              <div
                className="InstagramEmbed"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <InstagramEmbed url="https://www.instagram.com/p/DDEwjJ2zFWt/" />
              </div>
            </div>

            <div className="col-md-3 col-12">
              <div
                className="InstagramEmbed"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <InstagramEmbed url="https://www.instagram.com/p/DC8V_c0z_TC/" />
              </div>
            </div>

            <div className="col-md-3 col-12">
              <div
                className="InstagramEmbed"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <InstagramEmbed url="https://www.instagram.com/p/DC3fyEBRXvK/" />
              </div>
            </div>

            <div className="col-md-3 col-12">
              <div
                className="InstagramEmbed"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <InstagramEmbed url="https://www.instagram.com/p/DDBm9nqxTO7/" />
              </div>
            </div>

            <div className="col-md-3 col-12">
              <div
                className="InstagramEmbed"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <InstagramEmbed url="https://www.instagram.com/p/DC_n0nwz0bU/" />
              </div>
            </div>

            <div className="col-md-3 col-12">
              <div
                className="InstagramEmbed"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <InstagramEmbed url="https://www.instagram.com/p/DC8tn9BzmaO/" />
              </div>
            </div>

            <div className="col-md-3 col-12">
              <div
                className="InstagramEmbed"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <InstagramEmbed url="https://www.instagram.com/p/DC875RcTaCu/" />
              </div>
            </div>

            <div className="col-md-3 col-12">
              <div
                className="InstagramEmbed"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <InstagramEmbed url="https://www.instagram.com/p/DC8ixi4z434/" />
              </div>
            </div>

            <div className="col-md-3 col-12">
              <div
                className="InstagramEmbed"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <InstagramEmbed url="instagram.com/p/DCmEZw7MDSf/" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
