// Import necessary components from react-router-dom
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Blog from './Blog/Blog';
import './App.css';
import Home from './Home/Home';
import StandingPage from './Standings/StandingPage';
import Team from './Team/Team';
import About from './About';
import Blogdetails from './Blog/Blogdetails';
import Video from './Video';
import Registration from './Registration';
import Trailcamp from './Trailcamp';
import Gallery from './Gallery';
import Social from './Social';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/news" element={<Blog />} />
        <Route path="/standings" element={<StandingPage />} />
        <Route path="/team" element={<Team />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog-details" element={<Blogdetails />} />
        <Route path="/video" element={<Video />} />
        <Route path="/player-registration" element={<Registration />}/>
        <Route path="/trail" element={<Trailcamp />}/>
        <Route path="/gallery" element={<Gallery />}/>
        <Route path="/social" element={<Social />}/>
        

      </Routes>
    </Router>
    </>
  );
}
export default App;
