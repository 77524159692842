import React from 'react';
import logo from './22.png'; // Update the path if necessary
import logo1 from './logowkl.png'
import linebg from '../linebg.png'
import { Link, NavLink } from 'react-router-dom';

export default function Header() {
  return (
    <>
      <div id="preheader">
        <div className="container-fluid">
          {/* Marquee Section */}
          <div className="row align-items-center">
            <div className="col-md-10 col-8">
              <div className="marquee">
                <span>
                  <i className="fa fa-circle"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                  Kabaddi: The game of India. The WKL is all set to be back with its power-packed season 2, brace yourselves for what's coming!
                </span>
              </div>
            </div>
            <div className="col-md-2 col-4 text-right">
              <NavLink target="_blank" to="https://www.facebook.com/profile.php?id=61567005626564" className="mr-2">
                <i className="fa fa-facebook-official" aria-hidden="true"></i>
              </NavLink>
              <NavLink target="_blank" to="https://www.linkedin.com/company/105152735/admin/dashboard/" className="mr-2">
                <i className="fa fa-linkedin-square" aria-hidden="true"></i>
              </NavLink>
              <NavLink target="_blank" to="https://www.youtube.com/@wklindiaoffical">
                <i className="fa fa-youtube-play" aria-hidden="true"></i>
              </NavLink>
              <NavLink target="_blank" to="https://www.instagram.com/wklindia/" className="mr-2">
                <i className="fa fa-instagram"></i>
              </NavLink>
            </div>
          </div>

          </div>
          </div>

          {/* Navigation Bar */}
          <nav className="navbar navbar-expand-lg  displaynonemb">
            <div className="container">
              {/* Navigation Links */}
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav w-100 justify-content-between align-items-center">
                  {/* Left Links */}
                  <div className="d-flex align-items-center">
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/">
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/about">
                        About Us
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/standings">
                        Standings
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/team">
                        Teams
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to="/team">
                        Matches
                      </NavLink>
                    </li>

                  </div>

                  {/* Center Logo */}
                  <li className="nav-item text-center">
                    <NavLink className="nav-link" to="/">
                     <div className='center-logo'>
                     <img src={logo} alt="Logo" />
                     </div>
                    </NavLink>

                  </li>

                  {/* Right Links */}
                  <div className="d-flex align-items-center">
                    
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/news">
                        News
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to="/social">
                        Media
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to="/video">
                        Video
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/gallery">
                        Gallery
                      </NavLink>
                    </li>

                  
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/trail">
                     
Selection Camp
                      </NavLink>
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </nav>





          <nav class="navbar navbar-expand-lg navbar-light bg-light hidedesktop">
          <div className='center-logo1'>
                     <img src={logo1} alt="Logo" className='img-fluid' />
                     </div>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav">
    <li className="nav-item">
                      <NavLink className="nav-link" to="/">
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/about">
                        About Us
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/standings">
                        Standings
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/team">
                        Teams
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to="/team">
                        Matches
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to="/news">
                        News
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to="/social">
                        Media
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to="/video">
                        Video
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/gallery">
                        Gallery
                      </NavLink>
                    </li>

                  
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/trail">
                     
Selection Camp
                      </NavLink>
                    </li>
    </ul>
  </div>
</nav>
    </>
  );
}
