import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import abstract from '../tv01.gif'
import abstract1  from '../arrowleft.gif'
import  videoimg from '../Vwkl.jpg'
import  videoimg1 from '../wklv22.jpg'

export default function Videocard() {
    const options = {
        loop: true,
        nav: false,
        dots: false,
        margin:20,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsive: {
          0: {
            items: 1.8, // 1 item for small screens
            margin:10,
            autoplayTimeout: 1000,
          },
          600: {
            items: 2, // 2 items for medium screens
          },
          1000: {
            items:4.2, // 3 items for large screens
          },
        },
      };

  return (
    <section id='newscard'>

<div className="heading-wkl">
            <h3>Trails Hightlights &nbsp;<img src={abstract} className="img-fluid arrowabtract" /></h3>
        </div>

      <div className=''>
        <OwlCarousel className='owl-theme' {...options}>
          <div className='item'>
            <div className='cardnews1'>
              <div className='img-box'>
                <img src={videoimg} className='img-fluid' alt='Kabaddi League' />
              </div>
           
            </div>
          </div>


          <div className='item'>
            <div className='cardnews1'>
              <div className='img-box'>
                <img src={videoimg1} className='img-fluid' alt='Kabaddi League' />
              </div>
           
            </div>
          </div>




         
          {/* Add more items as needed */}
        </OwlCarousel>
      </div>
    </section>
  );
}
