import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import banner1 from './banner22.jpg'
import banner10 from './Banner3.jpg'
import { Link } from 'react-router-dom';

const Banner = () => {
  const slides = [

    {
      id: 0,
      // title: "Women’s Kabaddi League Starting From Beginning Of The Year 2025..",
      image:banner1,
    },
  ];

  const options = {
    loop: true,
    nav: false,
    autoplay: true,
    autoplayTimeout:7000,
    autoplayHoverPause: true,
    items: 1, // Number of items to display
  };

  return (
    <>
    {/* <img src={banner} className='img-fluid desktopshow' width="100%"/> */}
   <OwlCarousel className="owl-theme desktopshow" {...options}>
        {slides.map((slide) => (
         <Link to="/player-registration">
          <div className="item" key={slide.id} style={{ backgroundImage: `url(${slide.image})`, height: '100vh', backgroundSize: 'cover', backgroundRepeat:'no-repeat'}}>
            <div className="overlay"></div>
            <div className="content">
              <h5>{slide.title}</h5>
            </div>
          </div>
          </Link>
        ))}
      </OwlCarousel>   

    </>
  );
};

export default Banner;
