import React, { useState, useEffect } from "react";
import arrowright from "./arrowright.svg";
import OtpInput from "react-otp-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "./Frame 563.svg";
import titleimg from "./33663 (3).png";
import lock from "./lock2.svg";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const WizardForm = () => {

  const navigate = useNavigate();  // Hook to navigate
  const [age, setAge] = useState(null);
  // State to manage form data
  const [showButton, setShowButton] = useState(false); // Initialize as boolean false
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const [currentStep, setCurrentStep] = useState(1);
  const [isDisabled, setIsDisabled] = useState(false);
  const [otp, setOtp] = useState("");
  const [base64, setBase64] = useState("");
  const [fileName, setFileName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [declare, setDeclare] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [profile_picture, setProfileImage] = useState("");
  const [title, setTitle] = useState("********");
  const [isSendOtpDisabled, setIsSendOtpDisabled] = useState(false);
  const [isOtpInputActive, setIsOtpInputActive] = useState(true);
  const [isVerifyInputActive, setIsVerifyInputActive] = useState(true);
  const [showSectorAndNoc, setShowSectorAndNoc] = useState(false);
  const[otpbtndisable,SetOtpbtndisable] = useState(true);
  const [timeLeft, setTimeLeft] = useState(0);
  const [canResend, setCanResend] = useState(true);
  const handleCheckboxChange2 = (event) => {
    setFormData({
      ...formData,
      declarationChecked: event.target.checked ? 1 : 0,
    });
  };
  

  const handleCheckboxChange = () => {
    setShowSectorAndNoc(!showSectorAndNoc);
    if (showSectorAndNoc) {
      setFormData((prev) => ({ ...prev, sector: "", noc: "" }));
    }
  };



  const maxRows = 5; // Define the maximum number of rows allowed

  const handleAddExperience = () => {
    if (experiences.length < maxRows) {
      setExperiences([
        ...experiences,
        { achievement: "", level: "", year: "", details: "", isOpen: true },
        { achievement: "", level: "", year: "", details: "", isOpen: true },
      ]);
    } else {

      toast.error(`You can only add up to ${maxRows} experiences`);
      
    }
  };

  const handleRemoveExperience = (index) => {
    // Prevent removal of the first two experiences
    if (experiences.length > 1) {
      setExperiences(experiences.filter((_, i) => i !== index));
    } else {
      toast.error("At least 1 experience entries are required.");
    }
  };

  const handleToggle = (index) => {
    const updatedExperiences = experiences.map((exp, i) =>
      i === index ? { ...exp, isOpen: !exp.isOpen } : exp
    );
    setExperiences(updatedExperiences);
  };

  


  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setCanResend(true);
    }
  }, [timeLeft]);

  const handleResendOTP = () => {
    setTimeLeft(300); // Reset timer to 5 minutes
    setCanResend(false);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const [experiences, setExperiences] = useState([
    { achievement: "", level: "", year: "", details: "", isOpen: true },
  
  ]);

  // console.log(experiences, "hello");
  const handleChange1 = (index, field, value) => {
    const updatedExperiences = [...experiences];
    updatedExperiences[index][field] = value;
    setExperiences(updatedExperiences);
    setFormData((prevState) => ({
      ...prevState,
      player_experience: updatedExperiences,
    }));
  };



  // const handleChange1 = (index, field, value) => {
  //   const updatedExperiences = experiences.map((exp, i) =>
  //     i === index ? { ...exp, [field]: value } : exp
  //   );
  //   setExperiences(updatedExperiences);
  // };



  const handleCheckboxChange3 = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      medical: prevFormData.medical === "Yes" ? "No" : "Yes" // Toggle between "Yes" and "No"
    }));
  };
  

  const handleRoleChange = (e) => {
    const value = e.target.value;
    setFormData((prev) => ({ ...prev, role: value, playing_position: "" }));
  };



  

  
  const today = new Date();
  const formattedDate = today.toLocaleDateString(); // Format date to a readable string
  const [formData, setFormData] = useState({
    name1: "",
    father_mother_name: "",
    date_of_birth: "",
    weight: "",
    height: "",
    complete_address: "",
    pincode: "",
    mobile_number__self: "",
    other_number: "",
    email_address: "",
    aadhar_number: "",
    passport_number: "",
    date: formattedDate,
    state: "",
    regn_no: "",
    raider: "",
    all_rounder: "",
    playing_position:"",
    institude:"",
    corner: "",
    preffered_trial_location: "",
    is_complete: 1,
    medical:"No",
    medicaldetails: "",
    profile_picture: profile_picture, // Initially empty
    player_experience: experiences,
    declarationChecked: 0, 
    gender:""

  });


  console.log(formData,"test")
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setBase64(base64String); // Base64 string is set here
        const uploadFile = async () => {
          const url = "https://dev-wkl.indiawkl.com/";
          const token = "a5927acc859fecf:4806e10ab1f7bcd";
          const formData = new URLSearchParams();
          formData.append("cmd", "uploadfile");
          formData.append("doctype", "File");
          formData.append("docname", "LEAD-00149");
          formData.append("filename", fileName);
          formData.append("filedata", base64String); // base64 encoded content
          formData.append("from_form", "1");
          try {
            const response = await fetch(url, {
              method: "POST",
              headers: {
                Authorization: `token ${token}`,
                "Content-Type": "application/x-www-form-urlencoded",
                Cookie:
                  "full_name=Guest; sid=Guest; system_user=no; user_id=Guest; user_image=",
              },
              body: formData.toString(),
            });

            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            setProfileImage(data.message.file_url);
            setFormData((prevState) => ({
              ...prevState,
              profile_picture: data.message.file_url,
            }));
            // console.log(data.message.file_url, "aksaj");
          } catch (error) {
            console.error("Error:", error);
          }
        };

        uploadFile();

        // Now, update formData with the base64 string
        // setFormData((prevFormData) => ({
        //   ...prevFormData,
        //   // profile_picture: profile_picture,
        // }));
      };
      reader.readAsDataURL(file); // Convert file to Base64
    }
  };

  const SenOtp = async (e) => {
    e.preventDefault();
    setShowButton(true); 
    if (formData.mobile_number__self.length !== 10) {
      toast.error("Enter a valid 10-digit mobile number.");
      return;
    }
    try {
      const res = await fetch(
        `https://dev-wkl.indiawkl.com/api/method/upkl.api.send_otp?mobile_number=${formData.mobile_number__self}`
      );
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await res.json();
      setResponse(data);
      SetOtpbtndisable(false)
      toast.success("OTP Sent Successfully");
      setTitle(formData.mobile_number__self);
      setTimeLeft(300);
      // Activate OTP inputsetTimeLeft(300);
      setCanResend(false);
      setIsOtpInputActive(true);
      setIsOtpInputActive(false);
      setIsVerifyInputActive(false);
    } catch (error) {
      toast.error("Failed to send OTP", formData.mobile_number__self);
      setTitle(formData.mobile_number__self);
      setTimeLeft(300);
      canResend(false);
      console.error(error);
    }
  };

  const handleVerification = async (e) => {
    e.preventDefault();
    const res = await fetch(`https://dev-wkl.indiawkl.com/api/method/upkl.api.verify_otp?mobile_number=${formData.mobile_number__self}&otp=${otp}`);
    const data = await res.json();
    if (data.message === "Verification Failed") {
      toast.error("Please Fill Proper Details");
    } else if (data.message === "Success" && data.data.is_complete === 1) {
      // toast.error("Already Register ");
      Swal.fire({
        title: 'warning',
        text: 'you’re already in our system',
        icon: 'error',
        confirmButtonText: 'OK',
        timer: 4000, // 4 seconds
        timerProgressBar: true, // Optionally show a progress bar
      }).then(() => {
        // Redirect after 4 seconds
        navigate('/'); // Replace with your target URL or route
      });


      setIsVerifyInputActive(true);
      setIsOtpInputActive(true);
    } else if (data.message === "Success" && data.data.is_complete === 0) {
      setCurrentStep(2);
    }
  };

  const [errors, setErrors] = useState({});

  const handleNext = () => {
    const validationErrors = validateStep(currentStep);
    if (Object.keys(validationErrors).length === 0) {
      setCurrentStep(currentStep + 1);
      setErrors({});
    } else {
      setErrors(validationErrors);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateStep = (step) => {
    const newErrors = {};

    if (step === 1) {
    }

    if (step === 2) {
      if (!formData.preffered_trial_location) {
        newErrors.preffered_trial_location = "Please select a trial location";
      }
      if (!formData.gender) {
        newErrors.gender = "Please select a trial location";
      }
      if (!formData.name1) newErrors.name1 = "Name is required.";
      if (!formData.father_mother_name)
        newErrors.father_mother_name = "Father/Mother name is required.";
      if (!formData.date_of_birth) newErrors.date_of_birth = "DOB Missing.";
      if (!formData.weight) newErrors.weight = "Weight Missing.";
      if (!formData.height) newErrors.height = "Height Missing.";
      if (!base64) newErrors.base64 = "Image Required."; // New validation for image upload

    }

    if (step === 3) {

    
        if (!formData.complete_address) {
          newErrors.complete_address = "Complete address is required.";
        }

        const pincodeRegex = /^\d{6}$/;
        if (!formData.pincode) {
          newErrors.pincode = "Pincode is required.";
        } else if (!pincodeRegex.test(formData.pincode)) {
          newErrors.pincode = "Pincode must be 6 digits.";
        }

        const mobileNumberRegex = /^\d{10}$/;
        if (!formData.other_number) {
          newErrors.other_number = "Mobile number is required.";
        } else if (!mobileNumberRegex.test(formData.other_number)) {
          newErrors.other_number = "Mobile number must be 10 digits.";
        }


        

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email_address) {
          newErrors.email_address = "Email address is required.";
        } else if (!emailRegex.test(formData.email_address)) {
          newErrors.email_address = "Email address is not valid.";
        }

        if (!formData.state) {
          newErrors.state = "Please select a state";
        }

        const AdrRegex = /^\d{12}$/;
        if (!formData.aadhar_number) {
          newErrors.aadhar_number = "Aadhar No";
        } else if (!AdrRegex.test(formData.aadhar_number)) {
          newErrors.aadhar_number = "Aadhar No. must be 12 digits.";
        }
      }

    if (step === 4) {

      if (!formData.role) {
        newErrors.role = "Role is required.";
      }
      if (formData.role && formData.role !== "All Rounder" && !formData.playing_position) {
        newErrors.playing_position = "required.";
      }
  
    
      if (showSectorAndNoc) {
        if (!formData.sector) {
          newErrors.sector = "Required.";
        }
        if (!formData.noc) {
          newErrors.noc = "Required.";
        }
        if (!formData.role) {
          newErrors.role = "Role is required.";
        }

        if (formData.role && formData.role !== "All Rounder" && !formData.playing_position) {
          newErrors.playing_position = "required.";
        }
      
        if ((formData.role === "Defender" || formData.role === "Raider") && !formData.playing_position) {
          newErrors.playing_position = "Required.";
        }



      }
    }
      // Role-based validation
     
if(step ===5)
{

  if (!formData.medicaldetails) {
    newErrors.medicaldetails = "medicaldetails is required.";
  }
 
  if (!declare) {
    setError('Please check the declaration box to proceed.');
    return;
}


let formErrors = {};



if (Object.keys(formErrors).length > 0) {
  setErrors(formErrors);
  return;
}



}

  
    return newErrors;
  };




  
 
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
  
    // Check if the declaration checkbox is checked
    // if (!formData.declarationChecked) {
    //   toast.error("Please check the declaration box before submitting.");
    //   return; 
    // }


   
  
    // Check if medical issue is "Yes" and medicaldetails is empty
    if (formData.medical === "Yes" && !formData.medicaldetails.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        medicaldetails: "Medical details are required.",
      }));
      return;
    }

    if (!formData.declarationChecked) {
      toast.error("Please check the declaration box before submitting.");
      return; // Stop the function if the checkbox is not checked
    }


    setIsSubmitDisabled(true);
    try {
      const response = await fetch(
        "https://dev-wkl.indiawkl.com/api/resource/WKL%20Registration?fields=[%22*%22]",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
  
      if (response.ok) {
        const result = await response.json();
        toast.success("Submitted Successfully");
        Swal.fire({
          title: 'Success!',
          text: 'Form is successfully submitted.',
          icon: 'success',
          confirmButtonText: 'OK',
          timer: 4000, // 4 seconds
          timerProgressBar: true,
        }).then(() => {
          navigate('/'); // Replace with your target URL or route
        });
  
      } else {
        console.error("Error:", response.statusText);
        toast.error("Submission failed. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    }
  };
  



  // const handleToggle = (index) => {
  //   const updatedExperiences = experiences.map((exp, i) => ({
  //     ...exp,
  //     isOpen: i === index ? !exp.isOpen : false, // close others when one is open
  //   }));
  //   setExperiences(updatedExperiences);
  // };



  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="col-12 align-self-center">
            <div className="registration-title">
              <div className="fit-wdith">
                <h4>Enter Your Mobile Number </h4>
                <div className="lineregistration"></div>
              </div>
            </div>
            <p>
              Register now for WKL 2025! Showcase your skills on the biggest
              platform.
            </p>
            <div className="row">
              <div className="col-md-7 col-12">
                <div className="form-group">
                  <label>Mobile Number</label>
                  <input
                    type="tel"
                    name="mobile_number__self"
                    placeholder={` ${errors.name1 ? "Enter 10 Digit" : "+91"}`}
                    className={`form-control ${
                      errors.mobile_number__self ? "error" : ""
                    }`}
                    value={formData.mobile_number__self}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-md-3 col-6">

              {otpbtndisable ? (
  <div className="sendotp ">
    <span
      onClick={SenOtp}
     
    >
      <button disabled={isSendOtpDisabled}>Send OTP</button>
      &nbsp;&nbsp;&nbsp;
      <img src={lock} className="lock-close" alt="Lock Icon" />
    </span>
  </div>
) : (
  // Else condition here, render an alternative or an empty fragment
  <>
  
  <div className="sendotp ">
    <span className="disable"
     
    >
      <button disabled>Send OTP</button>
      &nbsp;&nbsp;&nbsp;
      <img src={lock} className="lock-close" alt="Lock Icon" />
    </span>
  </div></>


)}


              </div>

              <div className="col-md-12">
                <div className="form-group1">
                  <div className="title-top">
                    <h5>
                      <span className="otpcode">OTP Code </span>Verification
                    </h5>
                    <p>
                      An OTP has been sent to your phone ({title}) . Please
                      enter it to proceed

                    </p>



                    {showButton &&  <button
      className="btn-reset"
      onClick={SenOtp}
      disabled={!canResend} // Disable the button if cannot resend
    >
      Resend OTP
    </button>}



      

                  </div>

                  <OtpInput
  value={otp}
  onChange={setOtp}
  numInputs={4}
  isInputNum={true}
  shouldAutoFocus={true}
  renderSeparator={<span>-</span>}
  renderInput={(props) => (
    <input
      {...props}
      type="text" // Use text for better compatibility
      inputMode="numeric" // Instruct browsers to show a numeric keypad
      pattern="[0-9]*" // Limit input to numbers
      disabled={isOtpInputActive} // Disable input until OTP is sent
    />
  )}
/>


                


                </div>
              </div>
            </div>
          </div>
        );

      case 2:
        return (
          <div>
            <div className="registration-title">
              <div className="fit-wdith">
                <h4>Personal Details</h4>
                <div className="lineregistration"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label
                    className={`form-controlyy ${errors.name1 ? "error" : ""}`}
                  >{` ${errors.name1 ? "Full Name" : "Full Name"}`}</label>
                  <input
                    type="text"
                    name="name1"
                    className="form-control"
                    value={formData.name1}
                    placeholder="Full Name"
                    onChange={handleChange}
                    class="form-control"
                  />
                </div>
              </div>



              <div className="col-md-6 col-12">
  <div className="form-group">
    <label
      className={`form-controlyy ${errors.gender ? "error" : ""}`}
    >
      Gender
    </label>
    <select
      className={`form-select ${errors.gender ? "is-invalid" : ""}`}
      value={formData.gender}
      onChange={handleChange}
      name="gender"
    >
      <option value="">Select Gender</option>
      <option value="Female">Female</option>
    </select>
  </div>
</div>





              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label
                    className={`form-controlyy ${
                      errors.father_mother_name ? "error" : ""
                    }`}
                  >{` ${
                    errors.father_mother_name ? "Father Name" : "Father Name"
                  }`}</label>

                  <input
                    type="text"
                    className="form-control"
                    name="father_mother_name"
                    class="form-control"
                    value={formData.father_mother_name}
                    placeholder="Father Name"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label
                    className={`form-controlyy ${
                      errors.date_of_birth ? "error" : ""
                    }`}
                  >{` ${errors.date_of_birth ? " DOB" : " DOB"}`}</label>

                  <input
                    type="date"
                    name="date_of_birth"
                    className="form-control"
                    placeholder={` ${
                      errors.date_of_birth ? "Enter DOB" : "Date of Birth"
                    }`}
                    value={formData.date_of_birth}
                    class="form-control"
                    max="2022-12-31"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-md-6 col-6">
                <div className="form-group">
                  <label
                    className={`form-controlyy ${errors.weight ? "error" : ""}`}
                  >{` ${errors.weight ? " Kg" : " Kg"}`}</label>

                  <input
                    type="tel"
                    class="form-control"
                    name="weight"
                    placeholder="Weight"
                    maxLength={3}
                    value={formData.weight}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6 col-6">
                <div className="form-group">
                  <label
                    className={`form-controlyy ${errors.height ? "error" : ""}`}
                  >{` ${errors.height ? "cm" : "cm"}`}</label>

                  <input
                    type="tel"
                    class="form-control"
                    placeholder="Height"
                    name="height"
                    maxLength={5}
                    value={formData.height}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-md-6 col-12">
  <div className="form-group">
    <label
      className={`form-controlyy ${
        errors.preffered_trial_location ? "error" : ""
      }`}
    >
      Location
    </label>
    <select
      className={`form-select ${errors.preffered_trial_location ? "is-invalid" : ""}`}
      value={formData.preffered_trial_location}
      onChange={handleChange}
      name="preffered_trial_location"
    >
      <option disabled value="">
        Preferred Trial Location
      </option>
      <option value="Jamshedpur (13th December)">
        Jamshedpur (13<sup>th</sup> December)
      </option>
      <option value="Hyderabad (19th December)">
        Hyderabad (19<sup>th</sup> December)
      </option>
    </select>
  </div>
</div>


              <div className="col-md-6 col-12">
                <div className="d-flex align-items-center">
                  <div className="file-preview">
                    {base64 ? (
                      <img
                        src={base64}
                        alt="Preview"
                        className="img-fluid preview-img"
                      />
                    ) : (
                      <div className="placeholder"></div>
                    )}
                  </div>
                  <label className="btn upload-btn" htmlFor="fileInput">
                  {errors.base64 ? <>
                  <i class="fa fa-cloud-upload" aria-hidden="true"></i><span className="eroortext"> 
                  &nbsp;  Image Required</span>
                     </>: 
                     <>
                  
                     <i class="fa fa-cloud-upload" aria-hidden="true">
                      </i>
                      
                      &nbsp; Upload Your Picture
                      
                      </>}

                    <input
                      type="file"
                      id="fileInput"
                      className="file-input"
                      onChange={handleFileChange}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <div className="registration-title">
              <div className="fit-wdith">
                <h4>Contact Details</h4>
                <div className="lineregistration"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className={`form-controlyy ${
                      errors.complete_address ? "error" : ""
                    }`}
                  >
                    {errors.complete_address ? " Address" : " Address"}
                  </label>
                  <input
                    type="text"
                    name="complete_address"
                    placeholder="Complete Address"
                    value={formData.complete_address}
                    onChange={handleChange}
                    className="form-control"
                  />
                  {/* {errors.complete_address && <small className="error-text">{errors.complete_address}</small>} */}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className={`form-controlyy ${
                      errors.pincode ? "error" : ""
                    }`}
                  >
                    {errors.pincode ? "Pincode" : "Pincode"}
                  </label>
                  <input
                    type="tel"
                    name="pincode"
                     maxLength="6"
                    placeholder="Pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                     className="form-control"
                  />
                  {/* {errors.pincode && <small className="error-text">{errors.pincode}</small>} */}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className={`form-controlyy ${
                      errors.other_number ? "error" : ""
                    }`}
                  >
                    {errors.other_number
                      ? "Whatsapp No."
                      : "Whatsapp No."}
                  </label>

                  
                  <input
                    type="tel"
                    name="other_number"
                    placeholder="Whatsapp No."
                    value={formData.other_number}
                    onChange={handleChange}
                   className="form-control"
                   maxLength="10" // Restricts the input to 10 characters
                   pattern="\d{10}" 
                  />
                  {/* {errors.mobile_number__self && <small className="error-text">{errors.mobile_number__self}</small>} */}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className={`form-controlyy ${
                      errors.email_address ? "error" : ""
                    }`}
                  >
                    {errors.email_address ? "Email" : "Email"}
                  </label>
                  <input
                    type="text"
                    name="email_address"
                    placeholder="Email Address"
                    value={formData.email_address}
                    onChange={handleChange}
                  className="form-control"
                  />
                  {/* {errors.email_address && <small className="error-text">{errors.email_address}</small>} */}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className={`form-controlyy ${
                      errors.aadhar_number ? "error" : ""
                    }`}
                  >
                    {errors.aadhar_number ? "Aadhar No." : "Aadhar No."}
                  </label>
                  <input
                    type="tel"
                    maxLength="12"
                    name="aadhar_number"
                    placeholder={errors.aadhar_number ? "Aadhar No. 12 digit" : "Aadhar No."}
                    value={formData.aadhar_number}
                    onChange={handleChange}
                    className="form-control"
                  />
                  {/* {errors.aadhar_number && <small className="error-text">{errors.aadhar_number}</small>} */}
                </div>
              </div>




              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label
                    className={`form-controlyy ${
                      errors.state ? "error" : ""
                    }`}
                  >
                    State
                  </label>
                  <select
                    className="form-select"
                    value={formData.state}
                    onChange={handleChange}
                    name="state"
                  >
                   <option value="">Select State</option>
  <option value="Andhra Pradesh">Andhra Pradesh</option>
  <option value="Arunachal Pradesh">Arunachal Pradesh</option>
  <option value="Assam">Assam</option>
  <option value="Bihar">Bihar</option>
  <option value="Chhattisgarh">Chhattisgarh</option>
  <option value="Goa">Goa</option>
  <option value="Gujarat">Gujarat</option>
  <option value="Haryana">Haryana</option>
  <option value="Himachal Pradesh">Himachal Pradesh</option>
  <option value="Jharkhand">Jharkhand</option>
  <option value="Karnataka">Karnataka</option>
  <option value="Kerala">Kerala</option>
  <option value="Madhya Pradesh">Madhya Pradesh</option>
  <option value="Maharashtra">Maharashtra</option>
  <option value="Manipur">Manipur</option>
  <option value="Meghalaya">Meghalaya</option>
  <option value="Mizoram">Mizoram</option>
  <option value="Nagaland">Nagaland</option>
  <option value="Odisha">Odisha</option>
  <option value="Punjab">Punjab</option>
  <option value="Rajasthan">Rajasthan</option>
  <option value="Sikkim">Sikkim</option>
  <option value="Tamil Nadu">Tamil Nadu</option>
  <option value="Telangana">Telangana</option>
  <option value="Tripura">Tripura</option>
  <option value="Uttar Pradesh">Uttar Pradesh</option>
  <option value="Uttarakhand">Uttarakhand</option>
  <option value="West Bengal">West Bengal</option>
  <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
  <option value="Chandigarh">Chandigarh</option>
  <option value="Daman and Diu">Daman and Diu</option>
  <option value="Lakshadweep">Lakshadweep</option>
  <option value="Delhi">Delhi</option>
  <option value="Puducherry">Puducherry</option>
  <option value="Ladakh">Ladakh</option>
  <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                  </select>
                </div>
              </div>


            </div>
          </div>
        );
      case 4:
        return (
         <div className="registration-title">
              <div className="registration-title">
              <div className="fit-wdith">
                <h4>Others Details</h4>
                <div className="lineregistration"></div>
              </div>
            </div>

            <p>
       If Student, Name of Institute 

              </p> 

            <div className="col-md-12">
                <div className="form-group">
                  <label
                    className={`form-controlyy ${
                      errors.institude ? "error" : ""
                    }`}
                  >
                    {errors.institude ? "Institute" : "Institute"}
                  </label>
                  <input
                    type="text"
                    name="institude"
                    placeholder="Name of Institute"
                    value={formData.institude}
                    onChange={handleChange}
                    className="form-control"
                  />
                  {/* {errors.aadhar_number && <small className="error-text">{errors.aadhar_number}</small>} */}
                </div>
              </div>


    
          {/* Checkbox to toggle Sector and NOC fields */}
          <div className="box-divider"></div>

          
          <div className="col-12">
          <p className="CHECKBOXTEXT"> <input
                type="checkbox"
                checked={showSectorAndNoc}
                onChange={handleCheckboxChange}

                />&nbsp;
         Working / Employed at  (Govt / Private Sector).

              </p>  </div>
    
          <div className="row">
            {showSectorAndNoc && (

              <>
                <div className="col-md-6">
                <p className="highlight">
                Select Employment  Sector

              </p> 
                  <div className="form-group">
                    <label
                      className={`form-controlyy ${
                        errors.sector ? "error" : ""
                      }`}
                    >
                      {errors.sector ? errors.sector : "Sector"}
                    </label>
                    <select

                      className="form-select"
                      value={formData.sector}
                      onChange={handleChange}
                      name="sector"
                    >
                      <option  value="">
                        Select Employment  Sector
                      </option>
                      <option value="Government">Government</option>
                      <option value="Private Sector">Private Sector</option>
                    </select>
                  </div>
                </div>
    
                <div className="col-md-6">

                <p className="highlight">
                NOC from Department if employed 

              </p> 

                  <div className="form-group">
                    <label
                      className={`form-controlyy ${
                        errors.noc ? "error" : ""
                      }`}
                    >
                      {errors.noc ? errors.noc : "NOC"}
                    </label>
                    <select
                      className="form-select"
                      value={formData.noc}
                      onChange={handleChange}
                      name="noc"
                    >
                      <option  value="">
                        Select NOC
                      </option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>
               

              </>

            )}
    
            {/* Dropdown for Role Selection */}

            <div className="col-md-6 col-12">
        <p className="highlight">Role</p>
        <div className="form-group">
          <label
            className={`form-controlyy ${errors.role ? 'error' : ''}`}
          >
            {errors.role ? errors.role : 'Role'}
            <span className="required">*</span> {/* Required asterisk */}
          </label>
          <select
            className="form-select"
            value={formData.role}
            onChange={handleChange}
            name="role"
            required
          >
            <option value="">Select Role</option>
            <option value="All Rounder">All Rounder</option>
            <option value="Raider">Raider</option>
            <option value="Defender">Defender</option>
          </select>
        </div>
      </div>


    
      {/* Show Playing Position dropdown if the selected role is Raider or Defender */}
      {(formData.role === "Raider" || formData.role === "Defender") && (
        <div className="col-md-6 col-12">
          <p className="highlight">Playing Position</p>
          <div className="form-group">
            <label
              className={`form-controlyy ${errors.playing_position ? 'error' : ''}`}
            >
              {errors.playing_position ? errors.playing_position : 'Playing Position'}
              <span className="required">*</span> {/* Required asterisk */}
            </label>
            <select
              className="form-select"
              value={formData.playing_position}
              onChange={handleChange}
              name="playing_position"
              required
            >

              {/* Show Defender options if role is 'Defender' */}
              {formData.role === 'Defender' && (
                <>
              <option value="">Select Position</option>

                  <option value="Right Corner">Right Corner</option>
                  <option value="Left Corner">Left Corner</option>
                  <option value="Right Cover">Right Cover</option>
                  <option value="Left Cover">Left Cover</option>
                  <option value="Right In">Right In</option>
                  <option value="Left In">Left In</option>
                </>
              )}

              {/* Show Raider options if role is 'Raider' */}
              {formData.role === 'Raider' && (
                <>
                <option value="">Select Position</option>
                  <option value="Right Raiders">Right Raiders</option>
                  <option value="Left Raiders">Left Raiders</option>
                </>
              )}
            </select>
          </div>
        </div>
      )}
     
          </div>
        </div>
        );
      case 5:
        return (
          <div>
            <div className="registration-title">
              <div className="fit-wdith">
                <h4>Kabaddi Experience </h4>
                <div className="lineregistration"></div>
              </div>
              <div className="separateexp"></div>
            </div>

            <>
            <div className="overflox">
      {experiences.map((exp, index) => (
        <div key={index} className="">
          



              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={`Achievement ${index + 1}`}
                      required
                      value={exp.achievement}
                      onChange={(e) =>
                        handleChange1(index, "achievement", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-md-2 col-6">
                  <select
                    className="form-select"
                    value={exp.level}
                    required
                    onChange={(e) =>
                      handleChange1(index, "level", e.target.value)

                    }
                  >
                    <option value="">Level</option>
                    <option value="Local">Local</option>
                    <option value="District">District</option>
                    <option value="State">State</option>
                    <option value="National">National</option>
                    <option value="International">International</option>
                  </select>
                </div>
                <div className="col-md-2 col-6">
                  <select
                    className="form-select"
                    value={exp.year}
                    required
                    onChange={(e) =>
                      handleChange1(index, "year", e.target.value)
                    }
                  >
                    <option value="">Year</option>
                    {[2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015,
2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005,
2004, 2003, 2002, 2001, 2000, 1999, 1998, 1997, 1996, 1995,
1994, 1993, 1992, 1991, 1990, 1989, 1988, 1987, 1986, 1985,
1984, 1983, 1982, 1981, 1980, 1979, 1978, 1977, 1976, 1975,
1974, 1973, 1972, 1971, 1970, 1969, 1968, 1967, 1966, 1965,
1964, 1963, 1962, 1961, 1960].map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 col-10">
                  <div className="form-group">
                    <input
                    required
                      type="text"
                      className="form-control"
                      placeholder="Details"
                      value={exp.details}
                      onChange={(e) =>
                        handleChange1(index, "details", e.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="col-md-1 col-2">
                <button
                className="btn btn-danger"
                onClick={() => handleRemoveExperience(index)}
              >
                -
              </button>
                </div>

          <div className="box-divider-exp"></div>

            </div>

        </div>
      ))}
        <button
          className="addmore"
          onClick={handleAddExperience}
          disabled={experiences.length >= maxRows}
        >
          Add More
        </button>
    </div>


    <div className="row">
    <div className="term-condit">
      
{/* <p> */}

            <p>
    <input
        type="checkbox"
        checked={formData.medical === "Yes"} // Check if medical is "Yes"
        onChange={handleCheckboxChange3} // Toggle on change
      />
      &nbsp;
      Any Health / Medical issues
      </p>
      

      <p>

      </p>


      {formData.medical === "Yes" && (
      <div>
        {errors.medicaldetails && (
          <span className="error">{errors.medicaldetails}</span>
        )}
        <input
          type="text"
          value={formData.medicaldetails}
          className='form-control'
          onChange={handleChange}
          name="medicaldetails"
          placeholder="Please specify medical issues"
        />
      </div>
    )}


  <p>  <input
            type="checkbox"
            checked={formData.declarationChecked}
            onChange={handleCheckboxChange2}
        /> 
        &nbsp; I {formData.name1}, declare that the information provided in this form
        is correct to the best of my knowledge. I will be fully responsible in case of any discrepancy.
        
        {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
        </p>
    </div>
    </div>
            </>
          </div>
        );
      case 5:
        return (
          <div>
            <h4>Review & Submit</h4>
            <pre>{JSON.stringify(formData, null, 2)}</pre>
            {/* You can display formData for review */}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
                <ToastContainer position="bottom-right" />


      <section id="registration-form">
        <div className="logo-register">
          <img src={titleimg} className="titleimg" />
        </div>

        {age}
        
        <form onSubmit={handleSubmit}>
        <h6 className="titlestep">Lock In Your Form-Filled! Register Now!</h6>

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4 p-0">

                <div className="vertical-progress-bar">
                  <ul className="progressbar">
                    <li className={currentStep >= 1 ? "completed" : "1"}>
                      <span className="circle">1</span>
                      <span className="label">Mobile Verification</span>
                    </li>

                    <li className={currentStep >= 2 ? "completed" : ""}>
                      <span className="circle">2</span>
                      <span className="label">Personal Details</span>
                    </li>
                    <li className={currentStep >= 3 ? "completed" : ""}>
                      <span className="circle">3</span>
                      <span className="label">Contact Details</span>
                    </li>
                    <li className={currentStep >= 4 ? "completed" : ""}>
                      <span className="circle">4</span>
                      <span className="label">Specialization</span>
                    </li>
                    <li className={currentStep >= 5 ? "completed" : ""}>
                      <span className="circle">5</span>
                      <span className="label active">Qualification</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-8 p-0">
                <div className="wizard-content">
                  {renderStep()}

                  <div className="form-navigation">
                    {currentStep === 1 ? (
                      <div className="verify">
                        <button
                          className="Verify Otp"
                          onClick={handleVerification}
                          disabled={isVerifyInputActive}
                        >
                          Verify OTP &nbsp;
                          <img src={arrowright} className="img-fluid" />
                        </button>
                      </div>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn btn-secondary pre-btn"
                          onClick={handlePrevious}
                          disabled={currentStep === 1}
                        >
                          <img
                            src={arrowright}
                            className="img-fluid"
                            alt="Prev"
                          />
                          &nbsp;&nbsp; Prev
                        </button>

                        <button
                          type="button"
                          className="btn btn-primary next-btn"
                          disabled={currentStep === 5 && isSubmitDisabled}
                          onClick={
                            currentStep === 5 ? handleSubmit : handleNext
                          }
                        >
                          {currentStep === 5 ? "Submit" : "Next"} &nbsp;&nbsp;
                          <img
                            src={arrowright}
                            className="img-fluid"
                            alt="Next"
                          />
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default WizardForm;
