import React from 'react'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import breadcumb from './bread (1).jpg';
import circle from './rotate.png'
import map1 from './mm4.png'
import arrow from './rightarrow.png'
import video from  './WKL-25 Video (Website) 14 LW.mp4'
import boximg from './wkl-mobile.png'
import boximg2 from  './boximage.png'
import boximg1 from './mm2.png'
export default function Trailcamp() {
  return (
<>

<Header/>

 <div className="breadcrumb">
        <img src={breadcumb} alt="Breadcrumb Image" className="breadcrumb-image" />
        <span className="breadcrumb-text"> WOMEN'S TRIAL CAMP</span>
      </div>

<section id='map'>

    <img src={map1} className='img-fluid  map'/>
<img src={boximg1}   className='img-fluid map1' />


</section>

<section className='space-between'>

</section>
<section id='trialcamp-video'>

<img src={boximg2}   className='img-fluid boximg' />



<div className='row'>
    <div className='trial-capitiom'>

        <div className='title-trail'>
            <h4>Womens Kabaddi League Starting From Next Year February 2025..</h4>
        </div>
<div className='arrowcenter'>
<img src={arrow}  className='img-fluid' />

</div>
        <p>The journey to the Women’s Kabaddi League 2025 is about to begin! We’re hosting selection camps across four cities — Chandigarh, Pune, Jamshedpur, and Hyderabad—and we’re looking for fearless players ready to prove their mettle. This is an amazing opportunity for women’s kabaddi players to bring their talent to the big stage, build lasting memories, and set new standards for the sport. Don’t miss your chance to shine and make 2025 the best season yet. The mat awaits you!</p>

        <div className="circle-animation">
  <img src={circle} className="img-fluid" alt="Rotating Circle" />
</div>

    </div>
</div>

</section>

<Footer/>
</>
  )
}
