import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import rightimg from './Rectangle 180 (1).png'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import highlight from './Frame 418.png'
import img1 from '../Teamlogo/img1 (2).jpg'
import img2 from '../Teamlogo/img2 (3).jpg'
import ani from '../Teamlogo/img3.jpg'
import n1 from '../Teamlogo/img5.jpg'
import n2 from '../Teamlogo/img6.jpg'
import n3 from '../Teamlogo/img41.jpg'
import { Link } from 'react-router-dom';


export default function Highlight() {

    const options = {
        loop: true,
        nav: false,
        dots: false,
        margin:0,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsive: {
          0: {
            margin:10,
            items: 2.2, // 1 item for small screens
          },
          600: {
            items: 1, // 2 items for medium screens
          },
          1000: {
            items:1, // 3 items for large screens
          },
        },
      };



  return (
   <section id='highlights'>

    <div className='conatiner-fluid'>
        <div className='row'>

        <div className='headingsection mx-auto'>
        <h4 className="text-white">NEWS & ARTICLES </h4>
      </div>
      
            <div className="col-md-9 col-12">
<div className='row'>
    <div className='col-md-5 col-12'>

      <Link target="_blank" to="https://www.aninews.in/news/business/a-new-era-for-kabaddi-indian-womens-kabaddi-league-wkl-2025-back-to-india-with-exciting-developments20241009175843/">
      <div className='left-highlight'>
            <div className='img-left'>
                <img src={ani} className='img-fluid'/>
            </div>
            <div className='left-caption'>
                <div className='date '>
                    <p ><span className='highlights-blink'>HIGHLIGHTS</span> 14 Oct 2024 | ANI </p>
                </div>
                <div className='heading'>
                    <h4>A New Era for Kabaddi: Indian Women's Kabaddi League ..</h4>
                    <p>A New Era for Kabaddi: Indian Women's ..</p>
                </div>
            </div>
        </div>
      
      </Link>
    </div>
    <div className='col-md-7 col-12'>
       <div className='hightlightcenter'>
       <div className='hightlightlist'>
            <div className='row'>
                <div className='col-md-4 col-5'>
                    <img src={n3} className='img-fluid'/>
                </div>
                <div className='col-md-8 col-7 align-self-center'>
                  <Link target='_blank' to="https://www.ahmedabadmirror.com/a-new-era-for-kabaddi-indian-womens-kabaddi-league-wkl-2025-back-to-india-with-exciting-developments/81877330.html">
                  <div className='hightlight-caption'>
                        <div className='highlights-tabs'>
                            <p>HIGHLIGHTS</p>
                             </div>
                        <p className='date'>
                        12 Oct 2024 | Ahmedabad Mirror
                        </p>
                        <h5>A New Era for Kabaddi: Indian Women’s Kabaddi League (WKL 2025)
</h5>
                        <div className='readmore-highlights'>
                            <p>Read more</p>
                        </div>
                    </div>
                  </Link>
                </div>

            </div>
        </div>

        <div className='hightlightlist'>
            <div className='row'>
                <div className='col-md-4 col-5 '>
                    <img src={n1} className='img-fluid'/>
                </div>
                <div className='col-md-8 col-7 align-self-center'>
                   <Link target='_blank' to="https://www.jagran.com/delhi/new-delhi-city-ncr-womens-kabaddi-league-returns-23814620.html">

                   <div className='hightlight-caption'>
                        <div className='highlights-tabs'>
                            <p>HIGHLIGHTS</p>
                             </div>
                        <p className='date'>
                        MON 04:30 2024 | Jagran 
                        </p>
                        <h5>Womens Kabaddi League league starting from the February 2025...</h5>
                        <div className='readmore-highlights'>
                            <p>Read more</p>
                        </div>
                    </div>

                    </Link>
                </div>

            </div>
        </div>


        <div className='hightlightlist'>
            <div className='row'>
                <div className='col-md-4 col-5 '>
                    <img src={n2} className='img-fluid'/>
                </div>
                <div className='col-md-8 col-7 align-self-center'>

                    <Link target="_blank" to="https://hindi.republicnewsindia.com/the-new-era-of-kabaddi-the-return-of-womens-kabaddi-league-and-exciting-developments/">
                    
                    <div className='hightlight-caption'>
                        <div className='highlights-tabs'>
                            <p>HIGHLIGHTS</p>
                             </div>
                        <p className='date'>
                        MON 04:30 2024 | R Bharat
                        </p>
                        <h5>Womens Kabaddi League league starting from the February 2025...</h5>
                        <div className='readmore-highlights'>
                            <p>Read more</p>
                        </div>
                    </div>
                    </Link>
                    
                </div>

            </div>
        </div>

       </div>


    
    </div>

</div>
            </div>
            <div className='col-md-3 col-12'>

            <OwlCarousel className='owl-theme' {...options}>

<div className='item'>
<img src={img1}  className='img-fluid'/>
</div>
<div className='item'>
<img src={img2}  className='img-fluid'/>
</div>
</OwlCarousel>
            </div>
        </div>
    </div>
   </section>
  )
}
